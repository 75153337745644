import React from "react"
import Link from "../../../Link/Link"
import ChevronRight from "./checkron-right.svg"
import "./NextLink.scss"

const NextLink = ({ to, label, title, lang }) => {
  return (
    <>
      <Link to={to} lang={lang} className="d-none d-md-flex next-link">
        <p className="next-link__label">{label}</p>
        <p className="next-link__title">{title}</p>
        <div className="next-link__icon">
          <img
            src={ChevronRight}
            alt="next link title"
            className="next-link__icon-image"
            loading="lazy"
          />
        </div>
      </Link>
      <Link to={to} lang={lang} className="d-md-none">
        <div className="w-100 d-flex align-items-center justify-content-between mb-2">
          <p className="next-link__label font-capriola">{label}</p>
          <div className="next-link__icon">
            <img
              src={ChevronRight}
              alt="next link title"
              className="next-link__icon-image"
              loading="lazy"
            />
          </div>
        </div>

        <p className="d-flex w-75 next-link__title font-capriola">{title}</p>
      </Link>
    </>
  )
}

export default NextLink
