import React from "react"
import { Col, Row } from "reactstrap"

import "../../components/ContraceptiveQuizCta/ContraceptiveQuizCta.scss"
import Link from "../Link/Link"
import ExternalCondom from "../../images/external-condom.svg"

const ContraceptiveQuizBlock = ({ data }) => {
    return (
        <section className="contraceptive-quiz margin-bottom padding contraceptive-quiz">
            
            <section className="container">
            <Row>
            {data &&
            data.map((item) => (
                <Col xs={{ order: 2, size: 12 }} lg={{ order: 1, size: 6 }}>
                <section className="contraceptive-quiz__content">
                    <h2 className="contraceptive-quiz__title">{item.node.title}</h2>
                    
                    <section className="contraceptive-quiz__description"
                    dangerouslySetInnerHTML={{ __html: item.node.content }}
                    />
                    <Link
                        to={item.node.generic_block_data.linkTo.url}
                        lang={item?.node?.fmmCore?.languageCode}
                        className= "contraceptive-quiz__link button"
                        >
                        {item.node.generic_block_data.linkLabel}
                    </Link>
                </section>
                </Col>
                ))}
                <Col xs={{ order: 1, size: 12 }} lg={{ order: 2, size: 6 }}>
                <section className="contraceptive-quiz__image">
                    <img
                    className="contraceptive-quiz__image-src"
                    src={ExternalCondom}
                    alt="External Condom"
                    loading="lazy"
                    />
                </section>
                </Col>
            </Row>
            </section>
        </section>
    )
}
export default ContraceptiveQuizBlock