import React from "react"
import Link from "../../../Link/Link"
import "./BreadCrumb.scss"

const BreadCrumb = ({ crumbs, lang }) => {
  return (
    <nav className="bread-crumb">
      {crumbs.map((crumb, index) => (
        <span key={index}>
          {index > 0 && <span className="bread-crumb__separator"> / </span>}
          {crumb.path ? (
            <Link to={crumb.path} lang={lang} className="bread-crumb__link">
              {crumb.label}
            </Link>
          ) : (
            <span className="bread-crumb__label">{crumb.label}</span>
          )}
        </span>
      ))}
    </nav>
  )
}

export default BreadCrumb
