import React, { useState } from "react"
import "./Slider.scss"

const Slider = ({ itemsToShow, children }) => {
  const [startIndex, setStartIndex] = useState(0)

  const handleNextClick = () => {
    setStartIndex(prevIndex =>
      Math.min(prevIndex + 1, React.Children.count(children) - itemsToShow)
    )
  }

  const handlePrevClick = () => {
    setStartIndex(prevIndex => Math.max(prevIndex - 1, 0))
  }

  return (
    <div className="slider">
      <div className="slider__container">
        {React.Children.toArray(children).slice(
          startIndex,
          startIndex + itemsToShow
        )}
      </div>
      <button
        className="slider__button slider__button-prev"
        onClick={handlePrevClick}
        disabled={startIndex === 0}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
        >
          <path
            d="M19.5684 4.37068C20.1379 3.79817 21.064 3.79694 21.635 4.36795C22.205 4.93789 22.205 5.86195 21.635 6.43189L12.067 15.9999L21.635 25.5679C22.205 26.1379 22.205 27.0619 21.635 27.6319C21.064 28.2029 20.1379 28.2017 19.5684 27.6292L8.00033 15.9999L19.5684 4.37068Z"
            fill={startIndex === 0 ? "#5E6673" : "#F78F2D"}
          />
        </svg>
      </button>
      <button
        className="slider__button slider__button-next"
        onClick={handleNextClick}
        disabled={startIndex === React.Children.count(children) - itemsToShow}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
        >
          <path
            d="M12.4316 4.37068C11.8621 3.79817 10.936 3.79694 10.365 4.36795C9.79504 4.93789 9.79504 5.86195 10.365 6.43189L19.933 15.9999L10.365 25.5679C9.79504 26.1379 9.79504 27.0619 10.365 27.6319C10.936 28.2029 11.8621 28.2017 12.4316 27.6292L23.9997 15.9999L12.4316 4.37068Z"
            fill={
              startIndex === React.Children.count(children) - itemsToShow
                ? "#5E6673"
                : "#F78F2D"
            }
          />
        </svg>
      </button>
    </div>
  )
}

export default Slider
