import React from "react"

import "./HorizontalScroll.scss"

const HorizontalScroll = ({ children }) => {
  return (
    // <div className="horizontal-scroll-container">
    <div className="horizontal-scroll">
      {React.Children.map(children, (child, index) => (
        <div key={index} className="card-container">
          {child}
        </div>
      ))}
    </div>
    // </div>
  )
}

export default HorizontalScroll
