import React from "react"
import { navigate } from "gatsby"

import "./ArticleCard.scss"
import { truncateExcerpt } from "../../lib/utils"

const ArticleCard = ({ imgUrl, title, excerpt, url, lang }) => {
  const navigateToBlog = () => {
    if (lang !== "en") {
      navigate(`/${lang}/${url}`)
    } else {
      navigate(`/${url}`)
    }
  }

  return (
    <div className="articles-card">
      <figure className="articles-card__image" onClick={navigateToBlog}>
        <img
          src={imgUrl}
          alt={title}
          className="articles-card__image-element"
        />
      </figure>
      <div className="articles-card__content">
        <h4 className="articles-card__title">{title}</h4>
        {excerpt && (
          <section
            className="articles-card__description"
            dangerouslySetInnerHTML={{ __html: truncateExcerpt(excerpt) }}
          />
        )}
      </div>
    </div>
  )
}

export default ArticleCard
