import React from "react"

import "./ContraceptiveMethodCard.scss"
import Link from "../Link/Link"

const ContraceptiveMethodCard = ({
  imgFile,
  heading,
  subHeading,
  link,
  lang,
}) => {
  return (
    <Link to={link} lang={lang} className="con-method-card">
      <div
        className={`con-method-card__image ${
          heading !== "See All Methods" ? "blue-bg-color" : "default-bg-color"
        }`}
      >
        <img
          src={imgFile}
          alt={heading}
          className="con-method-card__image-element"
        />
      </div>
      <div className="con-method-card__content">
        <h6 className="con-method-card__title">{heading}</h6>
        <p className="d-none d-md-block con-method-card__description">
          {subHeading}
        </p>
      </div>
    </Link>
  )
}

export default ContraceptiveMethodCard
